import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider, MixpanelConsumer } from "react-mixpanel";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("app");
const root = createRoot(container);
mixpanel.init("30c1e17064b73a72c4460f922f97c202");

root.render(
  <BrowserRouter>
    <MixpanelProvider mixpanel={mixpanel}>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </MixpanelProvider>
  </BrowserRouter>
);
