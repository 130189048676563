/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";

import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import Profile from "layouts/profile";

import Guest from "app/guests/guestLayout";
import Admin from "app/admin/adminLayout";
import Parent from "app/parents/parentLayout";
import NotFound from "app/common/not-found-page";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { direction, darkMode } = controller;

  const { pathname } = useLocation();
  const apiKey = "7e6558e3d7bc227eb6954122decfd0e5"; // Your Mixpanel API Key
  const apiSecret = "1dbb3ff4dba9ebdac39b9335a00ba242"; // Your Mixpanel API Secret
  // iaYaflxYnFpfS0HU6NUdnYSvAZITCG7o
  // hashika.8af69b.mp-service-account

  // Define the Mixpanel API endpoint
  const mixpanelEndpoint = `https://try.readme.io/https://mixpanel.com/api/2.0/events`;

  // Define your query parameters (e.g., event name, date range)
  const queryParams = {
    project_id: 3108116,
    from_date: "2023-01-25", // Replace with your desired date range
    to_date: "2023-12-26",
    event: ["all"],
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
    getMixPanelData();
  }, [direction]);

  const getMixPanelData = () => {
    // axios
    //   .get(mixpanelEndpoint, {
    //     params: queryParams,
    //     auth: {
    //       username: "7e6558e3d7bc227eb6954122decfd0e5",
    //       password: "",
    //     },
    //   })
    //   .then((response) => {
    //     // Handle the Mixpanel data returned in the response
    //     console.log("Mixpanel data:", response.data);
    //   })
    //   .catch((error) => {
    //     // Handle any errors that occur during the request
    //     console.error("Error fetching Mixpanel data:", error);
    //   });

    const options = {
      method: "GET",
      url: "https://try.readme.io/https://data.mixpanel.com/api/2.0/export",
      params: { from_date: "2023-01-01", to_date: "2023-09-26" },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        accept: "text/plain",
        authorization: "Basic MWRiYjNmZjRkYmE5ZWJkYWMzOWI5MzM1YTAwYmEyNDI6",
      },
    };

    axios
      .request(options)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      });
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        {/* <Route exact path="/guest/*" element={<Guest />} /> */}
        <Route path="/*" element={<Admin />} />
        {/* <Route path="/*" element={<Parent />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
