import React, { useEffect, useState } from "react";

import ReactPlayer from "react-player/youtube";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { getContentById } from "service/admin";

const SingleArticle = () => {
  const [articledata, setArticleData] = useState({
    id: 1,
    title: "",
    description: "",
    link: "",
    type: "mkt",
    created_at: "",
    updated_at: "",
  });

  useEffect(() => {
    // Parse the URL to extract the ID (assuming the ID is in the format /video/:id)
    const urlParts = window.location.pathname.split("/");
    const id = urlParts[urlParts.length - 1];

    getArticleData(id);
  }, []);

  const getArticleData = async (id) => {
    try {
      const contents = await getContentById(id);

      if (contents.data.status === 200) {
        setArticleData(contents.data.data);
      }
    } catch (err) {}
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <Typography variant="h4" gutterBottom>
              Best things for your kids...
            </Typography>

            <Card variant="outlined" sx={{ borderRadius: "16px", width: "80vw", margin: "auto" }}>
              <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                <div
                  style={{
                    position: "relative",
                    paddingTop: "56.25%",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ReactPlayer
                    url={articledata.link}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                    }}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid container item>
            <MDBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              p={4}
              sx={{ overflow: "hidden" }}
            >
              <Typography variant="h4" gutterBottom>
                {articledata.title}
              </Typography>

              <Typography variant="p" gutterBottom>
                {articledata.description}
              </Typography>
            </MDBox>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default SingleArticle;
