/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { getRole } from "core/services/auth";
import { useState } from "react";
import { deleteArticleById } from "service/admin";
import "./style.css";

function SimpleBlogCard({
  id,
  image,
  title,
  description,
  action,
  setIsDelete,
  setAlertOpen,
  isYoutube,
}) {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const user = getRole();

  const handleClickOpen = () => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteArticleById(id);
      setIsDelete(true);
      setAlertOpen(true);
      setOpen(false);
      toast.success("Article has deleted");
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setOpen(false);
      console.log(err);
    }
  };

  return (
    <Card>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="3%"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox p={3}>
        <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
          {title.length <= 40 ? title : title.substr(0, 40) + "..."}
        </MDTypography>
        <MDBox mt={2} mb={3}>
          <MDTypography variant="body2" component="p" color="text">
            {description.length <= 80 ? description : description.substr(0, 80) + "..."}
          </MDTypography>
        </MDBox>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {action.type === "external" ? (
            <MuiLink href={action.route} target="_blank" rel="noreferrer">
              <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
            </MuiLink>
          ) : (
            <Link to={isYoutube ? action.route : action.link} target={isYoutube ? "" : "_blank"}>
              <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
            </Link>
          )}
          {user === "admin" && (
            <div style={{ display: "flex", marginLeft: "20px" }} className="article-action">
              <Link to={`/article/update/${id}`} style={{ paddingRight: "5px" }}>
                <EditIcon
                  className="edit-icon"
                  style={{ backgroundColor: "#1a73e8", width: "30px", height: "30px" }}
                />
              </Link>
              <Link>
                <DeleteIcon
                  className="delete-icon"
                  onClick={handleClickOpen}
                  style={{ backgroundColor: "red", width: "30px", height: "30px" }}
                />
              </Link>
            </div>
          )}
        </div>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be undone once you delete the article
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton color={"dark"} onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton color="error" onClick={handleDelete}>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SimpleBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimpleBlogCard;
