import { Navigate, Outlet } from "react-router-dom";
import { getRole } from "core/services/auth";
import { getToken } from "core/services/auth";
import { PARENT } from "constants/role";
import { ADMIN } from "constants/role";
import { GUEST } from "constants/role";

const isAuthenticated = () => {
  const token = getToken();
  if (token) return true;
  return false;
};

export const NonAuthGuard = ({ element, ...rest }) => {
  const isAuthenticatedUser = isAuthenticated();
  const role = getRole();

  // if (isAuthenticatedUser) {
  //   if (role === PARENT) {
  //     return <Navigate to={"/dashboard"} />;
  //   }
  //   if (role === ADMIN) {
  //     return <Navigate to={"/admin/dashboard"} />;
  //   }
  //   if (role === GUEST) {
  //     return <Navigate to={"/guest/dashboard"} />;
  //   }
  // }
  return isAuthenticatedUser ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <Outlet /> // Redirect to the sign-in page if not authenticated
  );
};
