import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
var youtubeThumbnail = require("youtube-thumbnail");
// Overview page components
import Header from "layouts/profile/components/Header";
import MuiAlert from "@mui/material/Alert";

import { useEffect, useState } from "react";
import { getContents } from "service/admin";
import { Box, Snackbar } from "@mui/material";
import CustomizedSnackbars from "components/AlertNotification";

function Article() {
  const [articledata, setArticleData] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getArticleData();
  }, [isDelete]);

  const getArticleData = async () => {
    try {
      const contents = await getContents();
      if (contents.data.status === 200) {
        setArticleData(contents.data.data);
      }
      setIsDelete(false);
    } catch (err) {}
  };

  const setImage = (link, image) => {
    const isYoutubeUrl = link.includes("youtube") || link.includes("youtu.be");
    if (isYoutubeUrl) return youtubeThumbnail(link).medium.url;
    else return `https://kids.cyruscodelabs.com/backend/contents/${image}`;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        {/* {isDelete && (
          <Box sx={{ width: 500 }}>
            {buttons}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              // onClose={handleClose}
              autoHideDuration={6000}
            />
            <Alert severity="success" sx={{ width: "100%" }}>
              This is a success message!
            </Alert>
          </Box>
        )} */}
        {isDelete && <CustomizedSnackbars open={open} setOpen={setOpen} />}
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Content Management
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              create new content, delete outdated materials, and update existing information. This
              intuitive system empowers you to maintain your digital assets with ease, ensuring that
              your content is always current and relevant.
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/article/create">
                New Article
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        {articledata.length != 0 ? (
          <Grid container spacing={6}>
            {articledata.map((article, index) => (
              <Grid item xs={12} md={6} xl={3} key={index}>
                <BlogCard
                  id={article.id}
                  image={setImage(article.link, article.image)}
                  title={article.title}
                  description={article.description}
                  action={{
                    label: "Read Article",
                    color: "dark",
                    route: "/article/" + article.id,
                    type: "internal",
                    link: article.link,
                  }}
                  isYoutube={article.link.includes("youtube") || article.link.includes("youtu.be")}
                  setIsDelete={setIsDelete}
                  setAlertOpen={setOpen}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <MDBox mb={12} height="100%">
            <MDTypography variant="h3" fontWeight="medium">
              No articles to Display
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Article;
