import { getRole } from "core/services/auth";

export const hasAnyAccess = (arr) => {
  const role = getRole();
  if (role == "superadmin") {
    return true;
  } else {
    return arr?.includes(role);
  }
};
