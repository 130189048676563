// @mui material components
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "layouts/profile/components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { getUserById } from "service/admin";
import { updateUserById } from "service/admin";

function UserUpdate() {
  const { articledata, setArticleData } = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "mrk", // Default type
    phone_number: "",
    address: "",
    avatar: "",
    password: "",
    role_id: 1,
    district_id: 1,
    avatar_id: 1,
  });

  // Step 2: Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const res = await getUserById(id);
      const { first_name, last_name, email, phone_number, address, avatar } = res.data.data;
      const data = {
        first_name,
        last_name,
        phone_number,
        email,
        address,
        avatar,
      };

      const updateData = {
        ...formData,
        ...data,
      };

      setFormData(updateData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    updateUser();
  };
  const updateUser = async () => {
    try {
      const users = await updateUserById(id, formData);
      if (users.data.status == 200) {
        toast.success("User create Successfully");
        navigate("/users");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Update User Details
          </MDTypography>

          <MDBox mb={1}>
            <MDBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="first_name"
                      variant="standard"
                      label="first Name"
                      value={formData.first_name}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="last_name"
                      variant="standard"
                      label="last Name"
                      value={formData.last_name}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="email"
                      variant="standard"
                      label="email"
                      value={formData.email}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="phone_number"
                      variant="standard"
                      label="phone number"
                      value={formData.phone_number}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="address"
                      variant="standard"
                      label="address"
                      value={formData.address}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="avatar"
                      variant="standard"
                      label="avatar"
                      value={formData.avatar}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} alignItems="center" ml={-1}></Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    component={Link}
                    to="/users"
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="gradient" color="success">
                    Save
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        <Grid container spacing={6}></Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserUpdate;
