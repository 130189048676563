import userClient from "../../core/services/instance";

export const userRegister = (firstName, lastName, email, phone, address, password, otp) => {
  return userClient.post(`/register`, {
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    address,
    avatar: "abc",
    password,
    otp,
  });
};

export const userLogin = (email, password) => {
  return userClient.post(`/admin/login`, {
    email,
    password,
  });
};

export const sendOtp = (otp, phone) => {
  return userClient.post(`/login`, {
    otp,
    phone,
  });
};

export const sendPhone = (phone) => {
  return userClient.post(`/sendotp`, {
    phone,
  });
};

export const getAdminUsers = () => {
  return userClient.get(`/admin/users`);
};
