import { useState } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import Guest from "app/guests/guestLayout";
import { getRole } from "core/services/auth";
import { getToken } from "core/services/auth";
import { PARENT } from "constants/role";
import { ADMIN } from "constants/role";
import { GUEST } from "constants/role";

const isAuthenticated = () => {
  const token = getToken();
  if (token) return true;
  return false;
};

export const PrivateRoute = ({ element, ...rest }) => {
  const isAuthenticatedUser = isAuthenticated();
  const pathName = window.location.pathname.split("/")[1];
  const role = getRole();

  // if (!isAuthenticatedUser) {
  //   return <Navigate to="/phone" />; // Redirect to the sign-in page if not authenticated
  // }

  // if (pathName === "dashboard") {
  //   if (role === PARENT) {
  //     return isAuthenticatedUser ? (
  //       <Outlet />
  //     ) : (
  //       <Navigate to="/" /> // Redirect to the sign-in page if not authenticated
  //     );
  //   }
  //   return <Navigate to="/not-found" />; // Redirect to the sign-in page if not authenticated
  // }

  return isAuthenticatedUser ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" /> // Redirect to the sign-in page if not authenticated
  );
};
