import { Link, useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AvatarSelection from "components/Avatar";
import avatar1 from "assets/images/avatar/1.png";
import avatar2 from "assets/images/avatar/2.jpg";
import avatar3 from "assets/images/avatar/3.png";
import avatar4 from "assets/images/avatar/4.jpg";
// import logoutIcon from "assets/images/icons/logout.png";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import homeDecor1 from "assets/images/home-decor-1.jpg";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Autocomplete, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import { createUser } from "service/admin";
import { getUserRole } from "service/admin";
import { getAvatars } from "service/admin";
import { getAllDistrict } from "service/admin";

function UserCreate() {
  const { articledata, setArticleData } = useState([]);
  const { role, setRole } = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const avatars = [avatar1, avatar2, avatar3, avatar4];
  const [avaterlist, setAvatarList] = useState([]);
  const [isSelectAvatar, setIsSelectAvatar] = useState(false);
  const [district, setDistrict] = useState([]);
  const [address, setAddress] = useState("colombo");
  const [userRoleId, setUserRoleId] = useState("");
  const roles = [
    {
      value: 1,
      label: "Admin",
    },
    {
      value: 2,
      label: "Parent",
    },
    {
      value: 3,
      label: "Superadmin",
    },
    {
      value: 4,
      label: "Monitor",
    },
  ];

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "", // Default type
    phone_number: "",
    address: "",
    avatar_id: "1",
    password: "",
    role_id: 1,
    district_id: "",
  });

  useEffect(() => {
    userRole();
    getAvatar();
    getDistrictData();
  }, []);

  const userRole = async () => {
    try {
      const res = await getUserRole();
      if (res.data.status == 200) {
        setRole(res.data.data);
      }
    } catch (err) {}
  };

  const getAvatar = async () => {
    try {
      const res = await getAvatars();
      if (res.data.status == 200) {
        setAvatarList(res.data.data);
      }
    } catch (err) {}
  };

  // Step 2: Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    postArticleData();
  };
  const postArticleData = async () => {
    setFormData({
      ...formData,
      district_id: address,
      role_id: userRoleId,
    });
    try {
      const users = await createUser(formData);
      if (users.data.status == 200) {
        toast.success("User create Successfully");
        navigate("/users");
        setArticleData(users.data.data);
      }
    } catch (err) {
      console.log(err.response.data.message);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleAvatarSelection = (selectedAvatar) => {
    setSelectedAvatar(selectedAvatar);
    setFormData({
      ...formData,
      avatar: selectedAvatar.toString(),
    });
  };

  const saveProfile = () => {
    // Implement logic to save the selected avatar to the user's profile here
  };

  const getDistrictData = async () => {
    try {
      const query = {
        id: "1",
      };
      const contents = await getAllDistrict(query);

      if (contents.data.status === 200) {
        setDistrict(
          contents.data.data.map((data) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
      }
    } catch (err) {}
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Create New User
          </MDTypography>

          <MDBox mb={1}>
            <MDBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="first_name"
                      variant="standard"
                      label="first Name"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="last_name"
                      variant="standard"
                      label="last Name"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="email"
                      variant="standard"
                      label="email"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="phone_number"
                      variant="standard"
                      label="phone number"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={8}>
                    <MDInput
                      name="address"
                      variant="standard"
                      label="address"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <MDBox mb={0}>
                      <Autocomplete
                        options={district}
                        fullWidth
                        name="district_id"
                        variant="standard"
                        value={district.find((option) => option.value === address)} // Match based on 'value'
                        onChange={(e, value) => setAddress(value ? value.value : "")}
                        renderInput={(params) => <TextField {...params} label="district" />}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={0}>
                      <Autocomplete
                        options={roles}
                        fullWidth
                        name="role_id"
                        variant="standard"
                        value={roles.find((option) => option.name === userRoleId)} // Match based on 'value'
                        onChange={(e, value) => setUserRoleId(value ? value.value : "")}
                        renderInput={(params) => <TextField {...params} label="role" />}
                      />
                    </MDBox>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="role_id"
                        name="role_id"
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Admin</MenuItem>
                        <MenuItem value={2}>Parent</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="password"
                      name="password"
                      variant="standard"
                      label="password"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={8}>
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ fontSize: "14px" }}
                      >
                        Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="type"
                        label="Type"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="mrk"
                          control={<Radio />}
                          label="Marketing"
                          checked={formData.type === "mrk"}
                        />
                        <FormControlLabel value="edu" control={<Radio />} label="Educational" />
                      </RadioGroup>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} alignItems="center" ml={-1}>
                    <MDButton
                      type="button"
                      variant="gradient"
                      color="success"
                      onClick={() => setIsSelectAvatar(true)}
                    >
                      Select Avatar
                    </MDButton>
                  </Grid>
                  {isSelectAvatar && (
                    <MDBox p={2}>
                      <Grid xs={12} alignItems="itemcenter" ml={-1}>
                        <AvatarSelection
                          avatars={avaterlist}
                          selectedAvatar={selectedAvatar}
                          onSelectAvatar={handleAvatarSelection}
                          saveProfile={saveProfile}
                        />
                      </Grid>
                    </MDBox>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  xs={12}
                  my={2}
                  style={{ padding: "5px" }}
                >
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    component={Link}
                    to="/users"
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="gradient" color="success">
                    Save
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        <Grid container spacing={6}></Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserCreate;
