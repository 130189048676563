import { GUEST } from "constants/role";
import { ADMIN } from "constants/role";
import { PARENT } from "constants/role";
import { getRole } from "core/services/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Container from "@mui/material/Container";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.png";

const NotFound = () => {
  const [redirect, setRedirect] = useState("/");
  const role = getRole();
  useEffect(() => {
    setRedirect("/");
    // if (role === PARENT) setRedirect("/dashboard");
    // if (role === ADMIN) setRedirect("/admin/dashboard");
    // if (role === GUEST) setRedirect("/guest");
  }, []);
  console.log(redirect);
  return (
    <>
      <BasicLayout image={bgImage}>
        <Container maxWidth="lg">
          <MDTypography variant="h1" fontWeight="medium" color="primary" align="center" mt={1}>
            404
          </MDTypography>
          <MDTypography variant="h3" fontWeight="medium" color="primary" align="center" mt={1}>
            Page not found
          </MDTypography>
          <MDTypography variant="h3" fontWeight="small" color="primary" align="center" mt={1}>
            <Link to={redirect}>Home</Link>
          </MDTypography>
        </Container>
      </BasicLayout>
    </>
  );
};

export default NotFound;
