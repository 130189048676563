import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/Edit";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import * as xlsx from "xlsx";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
var youtubeThumbnail = require("youtube-thumbnail");
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import { getAllUsers } from "service/admin";
import moment from "moment/moment";
import MDInput from "components/MDInput";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { getAllBoardDetailData } from "service/admin";
import { getAllGames } from "service/admin";
import "./style.css";

function LeaderBoard() {
  const [userdata, setUserData] = useState([]);
  const [gamedata, setGameData] = useState([]);
  const [phone, setPhone] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [game, setGame] = useState("");

  useEffect(() => {
    getUsersData();
    getGamesData();
    Promise.all([getUsersData(), getGamesData()]).then((values) => {
      console.log(values);
    });
  }, []);

  const getUsersData = async () => {
    try {
      const query = {
        id: "1",
      };
      const contents = await getAllBoardDetailData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const getGamesData = async () => {
    try {
      const query = {
        id: "1",
      };
      const contents = await getAllGames(query);
      if (contents.data.status === 200) {
        setGameData(contents.data.data);
        // console.log(contents);
      }
    } catch (err) {}
  };

  const getFilter = async () => {
    try {
      // for now
      if (phone) {
        setUserData(userdata.filter((data) => data.child.parent.phone_number == phone));
        return;
      }
      const query = {
        id: game,
        from: startDate
          ? `${new Date(startDate).getFullYear()}-${(
              new Date(startDate).getMonth() +
              1 +
              ""
            ).padStart(2, "0")}-${(new Date(startDate).getDate() + "").padStart(2, "0")}`
          : "",
        to: endDate
          ? `${new Date(endDate).getFullYear()}-${(new Date(endDate).getMonth() + 1 + "")
              .padStart("")
              .padStart(2, "0")}-${(new Date(endDate).getDate() + "").padStart(2, "0")}`
          : "",
      };
      const contents = await getAllBoardDetailData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const reset = async () => {
    setStartDate("");
    setEndDate("");
    setGame("");
    setPhone("");
    try {
      const query = {
        id: "1",
        role: "",
        phonenumber: "",
        from: "",
        to: "",
        game: "",
      };
      const contents = await getAllBoardDetailData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const reportDownload = () => {
    const dataToExport = [];
    userdata.forEach(({ top_score, child }) => {
      dataToExport.push({
        Name: child.first_name + " " + child.last_name,
        "Top Score": top_score,
        "Parent User": child.parent.first_name + " " + child.parent.last_name,
        Date: moment(child?.updated_at).utc().format("YYYY-MM-DD"),
      });
    });
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Users");
    xlsx.writeFile(workbook, "users.xlsx");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Game Leaderboard
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Easily track child scores on the leaderboard. Filter and monitor performance data
              without modification privileges. Download reports for detailed analysis of child
              achievements.
            </MDTypography>
            {/* <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/user/create">
                New User
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        {/* <br />
      </Header> */}

        {/* <Header> */}
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDBox mb={1}>
            <MDBox mt={1} mb={1}>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      fullWidth={100}
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                    <DatePicker
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                    <Grid item xs={12} md={8}>
                      <MDInput
                        name="phone_number"
                        variant="outlined"
                        label="+94XXX or 071XXX"
                        fullWidth
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-label" fullWidth>
                          Game
                        </InputLabel>
                        <Select
                          style={{ width: "120px", height: "42px" }}
                          label="game"
                          variant="outlined"
                          name="role_id"
                          onChange={(e) => setGame(e.target.value)}
                        >
                          {gamedata.map((game, key) => {
                            return (
                              <MenuItem value={game.id} key={key}>
                                {game.name}
                              </MenuItem>
                            );
                          })}
                          {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={1}>Admin</MenuItem>
                          <MenuItem value={2}>Parent</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <MDBox mb={2}>
                      <Autocomplete
                        options={gamedata}
                        fullWidth
                        variant="standard"
                        value={gamedata.find((option) => option.id === game)} // Match based on 'value'
                        onChange={(e, value) => setGame(e.target.value)}
                        renderInput={(params) => <TextField {...params} label="game" />}
                      />
                    </MDBox> */}
                    <MDButton variant="gradient" color="info" onClick={getFilter}>
                      Filter
                    </MDButton>
                    <MDButton variant="gradient" color="dark" onClick={reset}>
                      Rest
                    </MDButton>
                    <MDButton variant="gradient" color="success" onClick={reportDownload}>
                      Report Download
                    </MDButton>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>

      <MDBox p={2}>
        {userdata.length != 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell align="left" variant="head">
                  Top Score
                </TableCell>
                <TableCell align="left" variant="head">
                  Parent Name
                </TableCell>
                <TableCell align="left" variant="head">
                  Parent Contact
                </TableCell>
                {/* <TableCell align="left" variant="head">
                  Parent Email
                </TableCell> */}
                <TableCell align="left" variant="head">
                  Date
                </TableCell>
                <TableCell align="right" variant="head"></TableCell>
              </TableRow>
              <TableBody>
                {userdata.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {user.child.first_name} {user.child.last_name}
                    </TableCell>
                    <TableCell>{user.top_score}</TableCell>
                    <TableCell>
                      {user.child.parent.first_name} {user.child.parent.last_name}
                    </TableCell>
                    <TableCell>{user.child.parent.phone_number}</TableCell>
                    {/* <TableCell>{user.child.parent.email}</TableCell> */}
                    <TableCell>
                      {moment(user.child?.updated_at).utc().format("YYYY-MM-DD")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MDBox mb={12} height="100%">
            <MDTypography variant="h3" fontWeight="medium">
              No games to Display
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default LeaderBoard;
