/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MDBox from "components/MDBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/CenteredBlogCard";

// Images
import bgImage from "assets/images/family-457235_640.jpg";

function Information() {
  return (
    <MDBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MDBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Seamless Learning Experience"
                    description="Our comprehensive approach integrates education and play seamlessly, fostering holistic child development."
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MDBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Secure and Hassle-Free Payments"
                    description="Rest easy knowing your child's learning journey is supported by our secure and convenient payment system."
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MDBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Ready-Made Learning Adventures"
                    description="Explore a world of educational fun with our prebuilt games and activities, designed just for little learners"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MDBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Enhanced Learning Environment"
                    description="Our continuous improvements ensure a safe, enriching, and engaging platform for your child's growth."
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={bgImage}
              title="Exploring Fun Made Easy"
              description="Parents and kids alike deserve a smooth, hassle-free experience while navigating Kids App. We're committed to delivering exceptional quality and user-friendly features to meet your high expectations."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Information;
