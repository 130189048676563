import mixpanel from "mixpanel-browser";

mixpanel.init("process.env.REACT_APP_MIXPANEL_TOKEN", {
  debug: true,
  // track_pageview: true,
  // ignore_dnt: true,
});

export const viewPageEvent = (name, event) => {
  mixpanel.track(name, event);
};
