import { USER_PHONE } from "constants/auth";
import { USER_ROLE } from "constants/auth";
import { AUTH_TOKEN } from "constants/auth";

export const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token);
};

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setRole = (role) => {
  localStorage.setItem(USER_ROLE, role);
};

export const getRole = () => {
  return localStorage.getItem(USER_ROLE);
};

export const setAuthUser = (user) => {
  return localStorage.setItem("auth-user", user);
};

export const setPhone = (phone) => {
  localStorage.setItem(USER_PHONE, phone);
};

export const getPhone = () => {
  return localStorage.getItem(USER_PHONE);
};
