import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DefaultInfoCard({ color, routepath, icon, title, description, value }) {
  return (
    <Link to={routepath}>
      <MDBox
        display="grid"
        justifyContent="center"
        alignItems="center"
        width="7rem"
        height="7rem"
        shadow="md"
        borderRadius="lg"
        bgColor="white"
      >
        <MDBox p={2} mx={3} display="flex" justifyContent="center">
          <MDBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            color={color}
            width="3rem"
            height="3rem"
            borderRadius="lg"
          >
            <FontAwesomeIcon icon={icon} size="3x" />
          </MDBox>
        </MDBox>
        <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
          <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
          {description && (
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {description}
            </MDTypography>
          )}
          {value && (
            <MDTypography variant="h5" fontWeight="medium">
              {value}
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Link>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
  routepath: "/",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  routepath: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoCard;
