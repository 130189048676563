import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";
import * as xlsx from "xlsx";
import LockOpenIcon from "@mui/icons-material/LockOpen";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
var youtubeThumbnail = require("youtube-thumbnail");
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import { getAllUsers } from "service/admin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDInput from "components/MDInput";
import { getRole } from "core/services/auth";
import { deleteUserById } from "service/admin";
import "./style.css";

function AdminUsers() {
  const [userdata, setUserData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs("2023-01-01"));
  const [endDate, setEndDate] = useState(dayjs("2023-01-01"));
  const [open, setOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    getArticleData();
  }, []);

  const getArticleData = async () => {
    try {
      const query = {
        role: "admin",
      };
      const contents = await getAllUsers(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
        console.log(contents);
      }
    } catch (err) {}
  };

  const reportDownload = () => {
    const dataToExport = [];
    userdata.forEach(({ first_name, email, address }) => {
      dataToExport.push({
        Name: first_name,
        Email: email,
        Address: address,
      });
    });
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Users");
    xlsx.writeFile(workbook, "users.xlsx");
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setUserId(id);
  };

  const handleResetClickOpen = (id) => {
    setResetOpen(true);
    setUserId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setResetOpen(false);
  };

  const deleteAccount = async () => {
    try {
      await deleteUserById(userId);
      setOpen(false);
      getUsersData();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Admin User Management
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              You can create new admin profiles, edit existing admin details, and apply filters to
              locate specific administrators swiftly. Additionally, the system offers the capability
              to generate and download reports ensuring comprehensive admin user management.
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/admin-user/create">
                New Admin
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>

      {/* <Header> */}
      <MDBox pt={2} px={2} lineHeight={1.25}>
        <MDBox mb={1}>
          <MDBox mt={1} mb={1}>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  {/* <DatePicker
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format="YYYY-MM-DD"
                  />
                  <DatePicker
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format="YYYY-MM-DD"
                  /> */}
                  {/* <MDButton variant="gradient" color="info">
                    Filter
                  </MDButton> */}
                  {/* <MDButton variant="gradient" color="success" onClick={reportDownload}>
                    Report Download
                  </MDButton> */}
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
      <br />
      {/* </Header> */}

      <MDBox p={2}>
        {userdata.length != 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell align="left" variant="head">
                  Email
                </TableCell>
                <TableCell align="left" variant="head">
                  Address
                </TableCell>
                <TableCell align="right" variant="head"></TableCell>
              </TableRow>
              <TableBody>
                {userdata.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.address}</TableCell>
                    <TableCell>
                      <Link
                        to={`/admin-user/update/${user.id}`}
                        style={{ paddingRight: "5px" }}
                        className="refresh-icon btn btn primary"
                      >
                        <EditIcon />
                      </Link>
                      <Link to="" onClick={() => handleClickOpen(user.id)} className="delete-icon">
                        <DeleteIcon />
                      </Link>
                      <Link
                        to=""
                        onClick={() => handleResetClickOpen(user.id)}
                        className="refresh-icon"
                      >
                        <LockOpenIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MDBox mb={12} height="100%">
            <MDTypography variant="h3" fontWeight="medium">
              No admin users to Display
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be undone once you remove
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton color={"dark"} onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton color="error" onClick={deleteAccount}>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={resetOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to update?"}</DialogTitle>
        <DialogContent>
          <MDInput
            name="text"
            variant="standard"
            label="password"
            fullWidth
            onChange={(e) => setResetPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MDButton color={"dark"} onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton color="success" onClick={deleteAccount}>
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default AdminUsers;
