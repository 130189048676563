// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/CustomDashboardLayout";
import DashboardNavbar from "examples/Navbars/CustomDashboardNavbar";
import Footer from "examples/Footer";
import BlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
var youtubeThumbnail = require("youtube-thumbnail");
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import { getContents } from "service/parent";
import { Link } from "react-router-dom";

function Article() {
  const [articledata, setArticleData] = useState([]);

  useEffect(() => {
    getArticleData();
  }, []);

  const getArticleData = async () => {
    try {
      const users = await getContents();

      if (users.data.status == 200) {
        setArticleData(users.data.data);
      }
    } catch (err) {}
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Parenting Tips and Advice
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="h4" color="text">
              Nurturing and Supporting Your Child&rsquo;s Growth
            </MDTypography>
            <MDTypography variant="button" color="text">
              Explore our collection of YouTube videos offering essential parenting insights. Get
              expert tips on nurturing your child&rsquo;s development and building strong
              parent-child relationships. Discover valuable advice for every stage of parenthood.
            </MDTypography>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        {articledata.length != 0 ? (
          <Grid container spacing={6}>
            {articledata
              .filter((article) => article.type === "mrk")
              .map((article, index) => (
                <Grid item xs={12} md={6} xl={3} key={index}>
                  <BlogCard
                    image={youtubeThumbnail(article.link).medium.url}
                    title={article.title}
                    description={article.description}
                    action={{
                      label: "Read Article",
                      color: "dark",
                      route: "/article/" + article.id,
                      type: "internal",
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <MDBox mb={12} height="100%">
            <MDTypography variant="h3" fontWeight="medium">
              No articles to Display
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Article;
